/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

$.fn.extend({
    animateCss: function (animationName, callback) {
        var animationEnd = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';
        this.addClass('animated ' + animationName).one(animationEnd, function () {
            $(this).removeClass('animated ' + animationName);
            if (callback) {
                callback();
            }
            console.log('animateCss method');
        });
        return this;
    },
    goTo: function (callback) {
        var href = $(this).children('a').attr('href');
        $(this).addClass("active");
        var speed = 1100;
        var destination = $(href).position().top - $('header').height();

        $("html:not(:animated),body:not(:animated)").animate({
            scrollTop: destination
        }, speed, function () {
            window.location.hash = href;
        });
        if (callback) {
            callback();
        }
    }
});

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages

                $('.click-right').on('click', function () {
                    var href = $(this).children('a').attr('href');
                    $(this).addClass("active");
                    var speed = 1100;
                    var destination = $(href).position().top - $('header').height();

                    $("html:not(:animated),body:not(:animated)").animate({
                        scrollTop: destination
                    }, speed, function () {
                        window.location.hash = href;
                    });
                });

                $('.front-slider').slick({
                    dots: false,
                    infinite: true,
                    speed: 500,
                    fade: true,
                    cssEase: 'linear',
                    autoplay: true,
                    autoplaySpeed: 2000
                });

                $('.front-slider2').slick({
                    dots: true,
                    infinite: true,
                    speed: 500,
                    autoplay: true,
                    autoplaySpeed: 2000
                });

                $('.td-menu').slick({
                    dots: false,
                    infinite: true,
                    slidesToShow: 1,
                    // slidesToScroll: 4,
                    centerMode: false,
                    variableWidth: true,
                    responsive: [
                        {
                            breakpoint: 800,
                            settings: {
                                slidesToShow: 3,
                                centerMode: true,
                                variableWidth: true,
                            }
                        }
                    ]
                });

                //featherlight front page
                $('.galls').featherlight({
                    targetAttr: 'href'
                });

                $('.gallery2').featherlight({
                    targetAttr: 'href'
                });

                $('.gallery3').featherlight({
                    targetAttr: 'href'
                });

                //my date
                if ($('.my-date').length > 0) {
                    $('.my-date').datetimepicker({
                        format: 'dd MM yyyy',
                        startView: 2,
                        minView: 2,
                        maxView: 2,
                        autoclose: true
                    });
                }
                if ($('.my-time').length > 0) {
                    $('.my-time').datetimepicker({
                        format: 'hh:ii',
                        startView: 1,
                        maxView: 1,
                        autoclose: true
                    });
                }

                $('.datetimepicker-minutes thead').attr('style', 'display:none;');
                $('.datetimepicker-minutes table').attr('style', 'width: 200px');
                $('.datetimepicker-minutes table tbody').attr('style', 'border-top: 0');
                $('.datetimepicker-hours thead').attr('style', 'display:none;');
                $('.datetimepicker-hours table').attr('style', 'width: 200px');
                $('.datetimepicker-hours table tbody').attr('style', 'border-top: 0');

                var countGall = 9;
                if ($(window).width() < 768) {
                    countGall = 3;
                }
                for (var x = 0; x < 4; x++) {
                    var monkeyList = new List('og-content' + x, {
                        valueNames: ['gallery2' + x],
                        //                        page: 9,
                        page: countGall,
                        pagination: true
                    });
                    if (x > 0) {
                        $('#og-content' + x).hide();
                    } else {
                        $('.ol-menu' + x).addClass('active');
                    }
                }

                $('.menu-gal ul li a').on('click', function () {
                    var x = $(this).attr('data');
                    $('.menu-gal ul li').removeClass('active');
                    $(this).parent().addClass('active');
                    $('.og-content').hide();
                    $(x).show();
                });

                //set active menu article medium and desktops
                $('.node-type-article .nav-right .dropdown-menu li:has(a[href="/article"]) a, .node-type-article .mediumNavContainer li:has(a[href="/article"]), .node-type-article #mobileNavList .dropdown-menu li:has(a[href="/article"]) a').addClass(function () {
                    return 'active';
                });
                $('.node-type-treatment .nav-left .dropdown-menu li:has(a[href="/treatment"]) , .node-type-treatment .mediumNavContainer li:has(a[href="/treatment"]), .node-type-treatment #mobileNavList .dropdown-menu li:has(a[href="/treatment"]) a').addClass(function () {
                    return 'active';
                });


                $(".search-btn").on('click', function () {
                    var x = $(".search-box").val();
                    location.href = "/search/node/" + x + "%20type%3Aarticle";
                });

                $('#edit-submitted-row-5-attachment-file-upload').change(function (e) {
                    var fileName = e.target.files[0].name;
                    // $('.file-name').html(fileName);
                    $('label[for="edit-submitted-row-5-attachment-file-upload"]').html(fileName);
                });

                $("#edit-submitted-row-5-attachment-file-ajax-wrapper").after('<div><p style="font-size: 9px; color: black; float: left;">The file must pdf, jpg, and png</p></div>');

                //nav sticky
                $('header').sticky({ topSpacing: 0 });

                //mobile
                // ,#mobileNavList .btn-close --> masuk difunction di bawah

                $('#btnMobileNavList').on('click', function () {
                    console.log($(this).prop('nodeName'));
                    $('#mobileNavList').slideToggle().toggleClass('opened');
                });

                //close nav menu ketika btn srt Appointment clicked --> fungsi di bawah disable karena nanti triger open lagi

                // $('#mobileNavList .click-right').on('click', function () {
                //     $(this).goTo(function () {
                //         $('#mobileNavList').slideToggle().toggleClass('opened');
                //     });
                // });

                $('#mobileNavList').on('click', function () {
                    $('#mobileNavList').slideToggle().toggleClass('opened');
                });
                //maedium
                $("#btnMediumNavList").on('click', function () {
                    $('#mediumNavContainer').fadeIn('fast', function () {
                        $("#mediumNavContainer .containerNav").addClass('active').animateCss('slideInLeft');
                    });
                });

                //close medium nav menu

                $('.containerNav .btn-close, .containerNav .click-right').on('click', function () {
                    $("#mediumNavContainer .containerNav").animateCss('slideOutLeft', function () {
                        $("#mediumNavContainer .containerNav").removeClass('active').parents('#mediumNavContainer').fadeOut('slow');
                    });
                });
                //close medium nav menu random klik
                $(document).click(function (e) {
                    if ($(".containerNav").hasClass("active")) {
                        if ($('#mediumNavContainer').is(e.target)) {
                            $("#mediumNavContainer .containerNav").animateCss('slideOutLeft', function () {
                                $("#mediumNavContainer .containerNav").removeClass('active').parents('#mediumNavContainer').fadeOut('slow');
                            });
                        }
                    }
                });

                $('#loader').delay(2500).hide();
                $('.treatment-det').delay(3000).show();

                $('.yutup').on('click', function () {
                    $(this).next().click();
                });

                $('#edit-mergevars-email--2').attr('placeholder', 'Email');
                // $('.article .col-md-7 a').next().remove();

                // var height = $('.article .img-responsive').height();
                // $('.article .col-sm-7').css('min-height', height);

                $('.article').each(function () {
                    var height = $(this).find('.img-responsive').height();
                    var height2 = $(this).find('.col-sm-7').height();
                    if (height > height2) {
                        $(this).find('.col-sm-7').css('min-height', height);
                        $(this).find('.col-sm-7 span').css({ 'bottom': '0', 'position': 'absolute' });
                        $(this).find('.col-sm-7 .ot-more').css({ 'padding-bottom': '0', 'bottom': '0' });
                    }
                });

            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.